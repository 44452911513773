import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/system';

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      '& .MuiTextField-root': {
        marginBottom: '10px !important',
      },
    },
    textStyl: {
      margin: '44px 0 35px 0 !important',
      textAlign: 'left',
      font: 'normal normal normal 20px/20px Roboto !important',
      letterSpacing: '0.07px',
      color: '#B90000',
      opacity: 1,
    },
    warnStyl: {
      margin: '44px 0 35px 0 !important',
      textAlign: 'left',
      font: 'normal normal normal 20px/20px Roboto !important',
      letterSpacing: '0.07px',
      color: '#F57C00',
      opacity: 1,
    },
    paper: {
      position: 'absolute',
      width: '20%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
    },

    transactionModal: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
    },

    saveContainer: {
      backgroundColor: 'white',
      padding: '10px 0',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '41px !important',
    },
    cancelButton: {
      margin: '0 15px !important',
      minWidth: '100px',
      textAlign: 'center',
      font: 'normal normal medium 16px/20px Roboto',
      letterSpacing: '0.06px',
      opacity: 1,
      height: '36px',
      color: '#515151 !important',
      border: '1px solid #DFDFDF !important',
    },
    saveButton: {
      margin: '0 15px !important',
      minWidth: '100px',
      textAlign: 'center',
      font: 'normal normal normal 16px/20px Roboto',
      letterSpacing: '0.06px',
      color: '#FFFFFF',
      opacity: 1,
      height: '36px !important',
      backgroundColor: '#D32F2F !important',
      fontWeight: 500,
    },
    warningButton: {
      margin: '0 15px !important',
      minWidth: '100px',
      textAlign: 'center',
      font: 'normal normal normal 16px/20px Roboto',
      letterSpacing: '0.06px',
      color: '#FFFFFF',
      opacity: 1,
      height: '36px !important',
      backgroundColor: '#1689FA !important',
      fontWeight: 500,
    },
    info: {
      textAlign: 'left',
      font: 'normal normal normal 14px/21px Roboto !important',
      letterSpacing: '0.08px',
      color: '#000000',
      opacity: 1,
    },
  })
);
